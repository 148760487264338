import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

interface ModularFurnitureProps {
  data: any;
}
const ModularFurniture: React.FC<ModularFurnitureProps> = ({ data }) => {
  return (
    <>
      <section className={styles.modfurnituresectiopn}>
        <div className={styles.modfurnitureinner}>
          <div className="home-container-1600">
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.modfurnitureheading}>
                  <div className={styles.modfurnitureheadingleft}>
                    <h2>{data?.title}</h2>
                    <p>{data?.subtitle}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.modfurniturecontent}>
              <div className="collectionsslider swiper-disabled-none">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={12}
                  navigation={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 12,
                    },
                    1300: {
                      slidesPerView: 4,
                      spaceBetween: 12,
                    },
                  }}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {data?.items?.map((category: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Link
                        href={category.link}
                        className={styles.modfurniturecard}
                      >
                        <div className={styles.modfurnitureimg}>
                          <figure>
                            <Image
                              width={445}
                              height={386}
                              quality={75}
                              loading="lazy"
                              src={category.imageUrl}
                              alt={category.alt}
                              title={category.name}
                            />
                          </figure>
                        </div>
                        <div className={styles.modfurnituretext}>
                          <h4>{category.name}</h4>
                          <p>{category.price.replace('Rs ', '₹')}</p>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className={styles.modfurniturbottom}>
              <Link
                href="/home-furnishing"
                className={`${styles['btn-primary']} btn btn-primary`}
              >
                View All
                <Image
                  src={'/images/categories/view-all-link-arrow.svg'}
                  alt="media"
                  width={17}
                  height={17}
                  quality={75}
                  loading="lazy"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ModularFurniture;
